import React from 'react';
import PropTypes from 'prop-types';

import Alphabet from '../../lib/alphabet';

import * as styles from './component.module.scss';

const LetterFilter = ({ setLetters, input, active = '' }) => {
  let y = Alphabet || [];
  const ad = Alphabet.map((el) => {
    const x = input.find((e) => el.value.includes(e));
    if (x) {
      return ({ ...el, isDisable: false });
    }
    return el;
  });
  if (ad) {
    y = ad;
  }

  return (
    <div className={styles.filterLetters}>
      {
        y.map((letter) => (
          <button key={letter.label} className={`${styles.filterLetter} ${active === letter.value ? styles.active : ''}`} type="button" value={letter.value} onClick={setLetters} disabled={letter.isDisable}>{letter.label}</button>
        ))
      }
      <button className={styles.filterClear} type="button" value="" onClick={setLetters}>Alle</button>
    </div>
  );
};

LetterFilter.defaultProps = {
  active: '',
};

LetterFilter.propTypes = {
  setLetters: PropTypes.func.isRequired,
  input: PropTypes.arrayOf(PropTypes.string).isRequired,
  active: PropTypes.string,
};

export default LetterFilter;
