const Alphabet = [
  {
    value: '0-1-2-3-4-5-6-7-8-9',
    label: '0-9',
    isDisable: true,
  },
  {
    value: 'A',
    label: 'A',
    isDisable: true,
  },
  {
    value: 'B',
    label: 'B',
    isDisable: true,
  },
  {
    value: 'C',
    label: 'C',
    isDisable: true,
  },
  {
    value: 'D',
    label: 'D',
    isDisable: true,
  },
  {
    value: 'E',
    label: 'E',
    isDisable: true,
  },
  {
    value: 'F',
    label: 'F',
    isDisable: true,
  },
  {
    value: 'G',
    label: 'G',
    isDisable: true,
  },
  {
    value: 'H',
    label: 'H',
    isDisable: true,
  },
  {
    value: 'I',
    label: 'I',
    isDisable: true,
  },
  {
    value: 'J',
    label: 'J',
    isDisable: true,
  },
  {
    value: 'K',
    label: 'K',
    isDisable: true,
  },
  {
    value: 'L',
    label: 'L',
    isDisable: true,
  },
  {
    value: 'M',
    label: 'M',
    isDisable: true,
  },
  {
    value: 'N',
    label: 'N',
    isDisable: true,
  },
  {
    value: 'O',
    label: 'O',
    isDisable: true,
  },
  {
    value: 'P',
    label: 'P',
    isDisable: true,
  },
  {
    value: 'Q',
    label: 'Q',
    isDisable: true,
  },
  {
    value: 'R',
    label: 'R',
    isDisable: true,
  },
  {
    value: 'S',
    label: 'S',
    isDisable: true,
  },
  {
    value: 'T',
    label: 'T',
    isDisable: true,
  },
  {
    value: 'U',
    label: 'U',
    isDisable: true,
  },
  {
    value: 'V',
    label: 'V',
    isDisable: true,
  },
  {
    value: 'W',
    label: 'W',
    isDisable: true,
  },
  {
    value: 'X',
    label: 'X',
    isDisable: true,
  },
  {
    value: 'Y',
    label: 'Y',
    isDisable: true,
  },
  {
    value: 'Z',
    label: 'Z',
    isDisable: true,
  },
];
export default Alphabet;
