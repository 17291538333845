import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import CmsImageComponent from '../../content-elements/image';

import * as brandStyle from './component.module.scss';

const BrandsCollection = ({ brands }) => (
  <div className={brandStyle.brands}>
    {brands.map(({
      id,
      name,
      isExternal,
      fullUrlPath,
      teaser,
    }) => {
      let LinkWrapper;
      if (isExternal) {
        // eslint-disable-next-line react/prop-types
        LinkWrapper = ({ children }) => (
          <a href={fullUrlPath} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      } else {
        // eslint-disable-next-line react/prop-types
        LinkWrapper = ({ children }) => (
          <Link to={fullUrlPath}>
            {children}
          </Link>
        );
      }

      return (
        <div key={id} className={brandStyle.brandItem}>
          <LinkWrapper>
            <div className={brandStyle.brandImageContainer}>
              {teaser?.gatsbyImageData && (
                <CmsImageComponent image={teaser} className={brandStyle.brandImage} />
              )}
              <div className={`${brandStyle.brandImageText} ${brandStyle.brandImageTextFade}`}>
                <h3>{name}</h3>
              </div>
            </div>
          </LinkWrapper>
        </div>
      );
    })}
  </div>
);

BrandsCollection.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isExternal: PropTypes.string.isRequired,
    fullUrlPath: PropTypes.string.isRequired,
    teaser: PropTypes.string,
  })).isRequired,
};

export default BrandsCollection;
