/* eslint-disable */

import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import t from '../../lib/translate';

import Layout from '../../layouts/default';

import Helmet from '../helmet';

import Header from '../../component-elements/header';
import Breadcrumb from '../../component-elements/breadcrumb';
import Section from '../../component-elements/content-section';
import LetterFilter from '../../component-elements/letter-filter';

import CmsImageComponent from '../../content-elements/image';
import CmsBrandContactComponent from '../../content-elements/brandContact';
import CmsShopCategoryComponent from '../../content-elements/shopCategory';
import CmsTextTeaserComponent from '../../content-elements/textTeaser';
import CmsBrandsSliderComponent from '../../content-elements/brandSlider';
import CmsTextSimpleComponent from '../../content-elements/textSimple';
import CmsNewsTeaserComponent from '../../content-elements/newsTeaser';
import ImageTextElement from '../../content-elements/imageTextElement';

import BrandsCollection from '../../brands-component/marken-collection';

const BrandListPage = ({
						   brandsByCollection: {nodes: collectionBrands},
						   brandsByCategory,
						   filterQueryNumber: {nodes: filterBrands},
						   datoCmsPage: {
							   name: pageName,
							   content: cmsContent,
							   seoMetaTags: helmetProps,
							   seo,
							   metaRobots,
							   metaCanonical,
							   textcolorHeader,
							   textcolorHeaderMobile,
							   header: headerElements
						   },
						   pageContext
					   }) => {
	const components = {
		DatoCmsTextSimple: CmsTextSimpleComponent,
		DatoCmsImage: CmsImageComponent,
		DatoCmsBrandContact: CmsBrandContactComponent,
		DatoCmsShopCategoryTeaser: CmsShopCategoryComponent,
		DatoCmsImageTextTeaser: CmsTextTeaserComponent,
		DatoCmsBrandSlider: CmsBrandsSliderComponent,
		DatoCmsNewsTeaser: CmsNewsTeaserComponent,
		DatoCmsImageTextElement: ImageTextElement
	};

	const brands = useMemo(() => {
		if(!brandsByCategory) {
			return collectionBrands;
		}

		const foundBrands = [];

		return [
			...collectionBrands,
			...(brandsByCategory?.nodes ?? [])
		].filter(function(brand) {
			if(foundBrands.includes(brand.id)) {
				return false;
			}
			foundBrands.push(brand.id);
			return true;
		});
	}, [collectionBrands, brandsByCategory]);

	const [letter, setLetter] = useState();
	const [active, setActive] = useState('');
	const setLetters = (e) => {
		setLetter(e.target.value);
		setActive(e.target.value);
	};

	const brandSort = ({name: aName}, {name: bName}) => aName.toLowerCase().localeCompare(bName.toLowerCase());

	const firstChar = [];
	brands
	.filter(({name}) => name !== null)
	.map(({name}) => firstChar.push(name.charAt(0).toUpperCase()));

	const sortedBrands = brands.sort(brandSort);
	let filterPosts = sortedBrands;

	if(letter) {
		if(letter.startsWith('0-1-2-3-4-5-6-7-8-9')) {
			filterPosts = filterBrands.sort(brandSort);
		} else {
			filterPosts = sortedBrands.filter(({name}) => name.toLowerCase()?.startsWith(letter.toLowerCase()));
		}
	}

	return (
			<Layout
					textcolorHeader={textcolorHeader}
					textcolorHeaderMobile={textcolorHeaderMobile}
			>
				<Breadcrumb items={[{id: pageName, name: pageName}]} />

				{headerElements?.length > 0 && <Header data={headerElements[0]} />}

				{cmsContent.map((singleComponent) => {
					if(
							typeof singleComponent === 'object'
							&& Object.keys(singleComponent).length > 0
					) {
						const Component = components[singleComponent.__typename];
						return (
								<Component
										key={singleComponent.id}
										{...singleComponent}
										swiperId={singleComponent.id}
								/>
						);
					}
					return (
							<div>
								<h4>This content element can not be rendered.</h4>
							</div>
					);
				})}

				<Section noGrid>
					<LetterFilter
							active={active}
							setLetters={setLetters}
							input={firstChar}
					/>
					<BrandsCollection brands={filterPosts} />
				</Section>

				<Helmet
						tags={helmetProps}
						metaRobots={metaRobots}
						metaCanonical={metaCanonical}
						title={seo?.title}
						titleFallback={t('brands_overview_title_fallback', pageName)}
						url={pageContext.url}
				/>
			</Layout>
	);
};

BrandListPage.propTypes = {
	datoCmsPage: PropTypes.objectOf(PropTypes.any).isRequired,
	brandsByCollection: PropTypes.objectOf(PropTypes.any).isRequired,
	brandsByCategory: PropTypes.objectOf(PropTypes.any).isRequired,
	filterQueryNumber: PropTypes.objectOf(PropTypes.any).isRequired,
	pageContext: PropTypes.objectOf(PropTypes.any).isRequired
};

export default BrandListPage;
