import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import BrandListPage from '../components/brand-list-page';

const AllBrandsByCategoryTemplate = (
  { data, pageContext },
) => <BrandListPage {...data} pageContext={pageContext} />;

AllBrandsByCategoryTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AllBrandsByCategoryTemplate;

export const query = graphql`
query AllBrandsByCategoryQuery($url: String, $lang: String, $brandCategories: [String!]) {
  datoCmsPage(url: {eq: $url}, locale: {eq: $lang}) {
    ...SeoMetaTags
    ...header
    ...content
    metaCanonical
    metaRobots
    textcolorHeader
    textcolorHeaderMobile
  }
  
  brandsByCollection: allDatoCmsBrand
  (
    filter: {
      locale: {eq: $lang}
      productCollections: {elemMatch: {productTypes: {elemMatch: {originalId: {in: $brandCategories}}}}}
    }
    sort: {
      order: ASC, fields: name
    }
  ) {
    nodes {
      id
      name
      fullUrlPath
      isExternal
      teaser { ...genericTeaserImage }
    }
  }

  brandsByCategory: allDatoCmsBrand(
    filter: {locale: {eq: $lang}, productTypes: {elemMatch: {originalId: {in: $brandCategories}}}}
    sort: {order: ASC, fields: name}
  ) {
    nodes {
      id
      name
      fullUrlPath
      isExternal
      teaser {
        ...genericTeaserImage
      }
    }
  }

  filterQueryNumber: allDatoCmsBrand(
    filter: {
      locale: {eq: "de"} 
      name: {regex: "/^[0-9]/"}
      productCollections: {elemMatch: {productTypes: {elemMatch: {originalId: {in: $brandCategories}}}}}
    }
    sort: {order: ASC, fields: name}
  ) {
    nodes {
      id
      name
      fullUrlPath
      isExternal
      teaser { ...genericTeaserImage }
    }
  }
}
`;
